export default function addATags(htmlText) {
    // Regular expression to match HTTP or HTTPS links
    var linkPattern = /(http[s]?:\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+)/g;

    // Find all matches in the HTML text
    var matches = htmlText.match(linkPattern);

    // Wrap each match in an <a> tag
    if (matches) {
        for (var i = 0; i < matches.length; i++) {
            var linkTag = '<a href="' + matches[i] + '">' + matches[i] + '</a>';
            htmlText = htmlText.replace(matches[i], linkTag);
        }
    }

    return htmlText;
}
