import styl from "./styl.module.css";

export default function PdfCard({ pdfName, pages, fileSize, handleDelete }) {
    const handleDownload = () => {

    };

    return (
        <div className={styl.pdfCard}>
            <button className={styl.delete} onClick={handleDelete}>
                <img src="/delete-white.png" />
            </button>
            <div className={styl.pdfPreview}>
                <img src="/stars.jpg" alt="PDF Preview" />
            </div>
            <div className={styl.pdfDetails}>
                <div className={styl.pdfDetailGroup}>
                    <div className={styl.pdfIcon}>
                        <img src="/pdf_icon.png" alt="PDF Icon" />
                    </div>
                    <div>
                        <p className={styl.pdfName}>{pdfName}</p>
                        <p className={styl.pdfMetadata}>
                            <span>{pages} pages</span>
                            <span>•</span>
                            <span>PDF</span>
                            <span>•</span>
                            <span>{fileSize}</span>
                        </p>
                    </div>
                </div>
                <button className={styl.download} onClick={handleDownload}>
                    <svg viewBox="0 0 34 34" height="34" width="34" preserveAspectRatio="xMidYMid meet" class=""
                        version="1.1" x="0px" y="0px" enable-background="new 0 0 34 34">
                        <title>download</title>
                        <path fill="#999"
                            d="M17,2c8.3,0,15,6.7,15,15s-6.7,15-15,15S2,25.3,2,17S8.7,2,17,2 M17,1C8.2,1,1,8.2,1,17 s7.2,16,16,16s16-7.2,16-16S25.8,1,17,1L17,1z"></path>
                        <path fill="#999"
                            d="M22.4,17.5h-3.2v-6.8c0-0.4-0.3-0.7-0.7-0.7h-3.2c-0.4,0-0.7,0.3-0.7,0.7v6.8h-3.2 c-0.6,0-0.8,0.4-0.4,0.8l5,5.3c0.5,0.7,1,0.5,1.5,0l5-5.3C23.2,17.8,23,17.5,22.4,17.5z"></path>
                    </svg>
                </button>
            </div>
        </div>
    )
}
