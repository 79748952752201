import moment from "moment";

export const isValidArray = (arr) => Array.isArray(arr) && arr.length > 0;

export const isValidDate = (dt) => {
  var timestamp = Date.parse(dt);
  return !isNaN(timestamp);
};

export const formatDate = (dt, format = "hh:mm") =>
  (isValidDate(dt) && moment(dt).format(format)) || dt;

export const clearSessionStorage = () => sessionStorage.clear();
