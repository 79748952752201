import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./login.scss";
import { formatDate } from "../../utils/helper";
import { API_URL, ROUTES } from "../../utils/constants";
import { postData } from "../../utils/restService";

const Login = () => {
  const navigate = useNavigate();
  const [loginPayload, setLoginPayload] = useState({
    username: "admin123",
    password: "password",
    phone: "",
    url: "",
    bot_id: "",
  });

  const [SIGN_UP, BOT_ID] = ["signup", "login"];

  const [selectedOption, setSelectedOption] = useState(SIGN_UP);

  const { username, password, phone, url, bot_id } = loginPayload;

  const isDisabled =
    !username ||
    username.length < 4 ||
    !password ||
    !password.length > 5 ||
    !phone ||
    !phone.length > 10 ||
    !url ||
    !/\bhttps?:\/\/\S+\b/.test(url);

  const handleChange = (e) => {
    setLoginPayload({ ...loginPayload, [e.target.name]: e.target.value });
  };

  const registerUser = async () => {
    const payload = { ...loginPayload };
    delete payload.bot_id;
    const userDetails = await postData(API_URL.REGISTER, payload);
    if (userDetails?.data?.bot_id) {
      // alert(
      //   `Registration completed, please use ${userDetails?.data?.bot_id} to login`
      // );
      alert(
        "“Registration completed. The chatbot Link is sent by whatsapp to your Phone number. Click on that link to access the chatbot."
      );
      // navigate(`${ROUTES.CHAT_ROUTE}/${userDetails?.data?.bot_id}`);
    } else if (userDetails?.error) {
      alert(userDetails.error);
    }
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    registerUser();
  };

  const handleChatClick = () => {
    if (bot_id) {
      navigate(`${ROUTES.CHAT_ROUTE}/${bot_id}`);
    }
  };

  return (
    <>
      <div className="marvel-device iphone-x">
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="volume"></div>
        <div className="camera"></div>
        <div className="screen">
          <div className="screen-container">
            <div className="status-bar">
              <div>
                <div className="time">{formatDate(new Date())}</div>
                <div className="battery">
                  <i className="zmdi zmdi-battery"></i>
                </div>
                <div className="network">
                  <i className="zmdi zmdi-network"></i>
                </div>
                <div className="wifi">
                  <i className="zmdi zmdi-wifi-alt-2"></i>
                </div>
                <div className="star">
                  <i className="zmdi zmdi-star"></i>
                </div>
              </div>
              <div className="placeholder">
              </div>
            </div>

            <div className="chat">
              <div className="chat-container">
                <div className="card">
                  <div className="img-container">
                    <img src="/avatar.jpg" alt="logo" />
                  </div>
                  <form>
                    {/* <div className="radio-div">
                      <input
                        type="radio"
                        id="signup"
                        name="login"
                        value={SIGN_UP}
                        checked={selectedOption === SIGN_UP}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      />
                      <span className="radio-label">Signup &nbsp;&nbsp;</span>
                      <input
                        type="radio"
                        id="botId"
                        name="login"
                        value={BOT_ID}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      />
                      <span className="radio-label">Login</span>
                    </div> */}
                    <div className="container">
                      {selectedOption === SIGN_UP && (
                        <div className="animate-div">
                          {/* <label>Username</label>
                          <input
                            type="text"
                            placeholder="Enter Username more than 4 chars"
                            name="username"
                            className="w-100 cust-input"
                            value={username}
                            onChange={handleChange}
                          />
                          <label>Password</label>
                          <input
                            type="password"
                            placeholder="Enter minimum 6 alphanumeric characters"
                            name="password"
                            required
                            className="w-100 cust-input"
                            maxLength={10}
                            value={password}
                            onChange={handleChange}
                          /> */}
                          <label style={{ width: "100%", textAlign: "left" }}>
                            Your WhatsApp Number
                          </label>
                          <p style={{ textAlign: "left" }}>
                            (Chatbot link will be sent to this number
                            immediately)
                          </p>
                          <input
                            type="number"
                            placeholder="Enter 10 digit WhatsApp Number"
                            name="phone"
                            required
                            className="w-100 cust-input"
                            value={phone}
                            onChange={handleChange}
                          />
                          <label>Url</label>
                          <input
                            type="text"
                            placeholder="Enter complete URL of your IndiaMart listing"
                            name="url"
                            required
                            className="w-100 cust-input"
                            value={url}
                            onChange={handleChange}
                          />
                          <button
                            disabled={isDisabled}
                            type="submit"
                            className={`login-btn ${isDisabled ? "disabled-button" : ""
                              }`}
                            onClick={handleLoginClick}
                          >
                            Register
                          </button>
                          <p className="helper-text-login">
                            (For connecting this chatbot to your business
                            number, please whatsapp{" "}
                            <a
                              target="_blank"
                              href="https://wa.me/+919561784681"
                            >
                              +919561784681
                            </a>{" "}
                            or email to{" "}
                            <a target="_blank" href="info@bagachat.com">
                              info@bagachat.com
                            </a>
                            )
                          </p>
                        </div>
                      )}
                      {/* <hr /> */}
                      {selectedOption === BOT_ID && (
                        <div className="animate-div">
                          <label>Bot ID</label>
                          <input
                            type="text"
                            placeholder="Enter Bot Id"
                            name="bot_id"
                            className="w-100 cust-input"
                            value={bot_id}
                            onChange={handleChange}
                          />
                          <button
                            disabled={!bot_id}
                            className={`login-btn ${!bot_id ? "disabled-button" : ""
                              }`}
                            onClick={handleChatClick}
                          >
                            Open Chat
                          </button>
                        </div>
                      )}
                    </div>
                    {/* <div className="container">
                      <span className="psw">
                        <a href="#">Forgot password?</a>
                      </span>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
