import axios from "axios";
import { ROUTES, API_RESPONSE_CODES, API_URL } from "./constants";
import { clearSessionStorage } from "./helper";

const { BASE_URL } = API_URL;

const config = {
  mode: 'cors',
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, HEAD, DELETE",
    Authorization: "",
  },
};

export const logout = () => clearSessionStorage();

const handleErrorResponse = async (err) => {
  const {
    UNAUTHORISED,
    FORBIDDEN,
    NOT_FOUND,
    BAD_REQUEST,
    INTERNAL_SERVER_ERROR,
    PAYLOAD_ERROR,
  } = API_RESPONSE_CODES;
  if (
    [
      UNAUTHORISED,
      FORBIDDEN,
      NOT_FOUND,
      BAD_REQUEST,
      INTERNAL_SERVER_ERROR,
      PAYLOAD_ERROR,
    ].includes(err?.response?.status)
  ) {
    // redirect
    const { status } = err.response;
    if (status === UNAUTHORISED) {
      logout();
    }
  }
  if (err) {
    if (err.code === "ERR_BAD_REQUEST" && err?.response?.data?.message) {
      return { error: err.response.data.message };
    }
    const errRes = err.toJSON();
    if (errRes.message === "Network Error") {
      // alert("No interenet connection !");
      logout();
    }
    return {
      error: errRes,
      errorCode: INTERNAL_SERVER_ERROR,
      isSuccessful: false,
      data: null,
    };
  }
  return err;
};

const handleSuccessResponse = (res) => {
  const { SUCCESS, SUCCESS_CREATE } = API_RESPONSE_CODES;
  if ([SUCCESS, SUCCESS_CREATE].includes(res.status)) {
    return res.data;
  }
  return res;
};

export const getData = (url, body) => {
  return axios
    .get(`${BASE_URL}${url}`, { data: body }, config)
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleErrorResponse(err));
};

export const postData = (url, body) => {
  return axios
    .post(`${BASE_URL}${url}`, body, config)
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleErrorResponse(err));
};

export const patchData = (url, body) => {
  return axios
    .patch(`${BASE_URL}${url}`, body, config)
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleErrorResponse(err));
};

export const putData = (url, body) => {
  return axios
    .put(`${BASE_URL}${url}`, body, config)
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleErrorResponse(err));
};

export const deleteData = (url, body) => {
  return axios
    .delete(`${BASE_URL}${url}`, { ...config, data: body })
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleErrorResponse(err));
};
