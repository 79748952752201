import { isValidArray } from "../../utils/helper";
import { ICONS } from "../../utils/constants";
import "./message.scss";
import ReactQuill from "react-quill-with-table";
import "react-quill-with-table/dist/quill.snow.css";
import { useState } from "react";
import PdfCard from "./PdfCard/PdfCard";
import Spinner from "./Spinner/Spinner";

const Message = ({
    recordId,
    inputText,
    outputText,
    img,
    pdf,
    video,
    options,
    time,
    isOwnMsg = false,
    listTitle,
    setReplyOptions,
    setIsReplyOptionOpened,
    setMessageEditId,
    handleMessageImageEdit,
    textEdit,
    handleMessageEditSubmit,
    handleUpload,
    handleDeleteImage,
    handleDeleteDocument,
    uploading
}) => {
    const getValidArr = (arr) =>
        (isValidArray(arr) && arr.filter((a) => a && a)) || [];

    const parse = require("html-react-parser");
    const { TICK } = ICONS;
    const [editedText, setEditedText] = useState(outputText);

    const imgArr = getValidArr(img?.split(",")) || [],
        pdfArr = getValidArr(pdf?.split(",")) || [],
        videoArr = getValidArr(video?.split(",")) || [];
    return (
        <>
            <div className={`message ${isOwnMsg ? "sent" : "received"}`}>
                {!isOwnMsg && (
                    <div style={{ width: '100%', height: '4rem' }}>
                        <input type="file" id="fileInput" multiple={true} style={{ display: 'none' }} onChange={async (e) => {
                            handleUpload(recordId, e.target.files);
                        }} />
                        <button disabled={uploading} className="upload-files-btn">
                            {uploading ? (
                                <div style={{ gap: '0.5rem' }}>
                                    <Spinner />
                                    <p>Uploading</p>
                                </div>
                            ) : (
                                <label htmlFor="fileInput" style={{ gap: '0.5rem' }}>
                                    <img src="/upload-white.png" alt="Upload More Files" height={20} width={20} />
                                    <p>
                                        Upload Files
                                    </p>
                                </label>
                            )}
                        </button>
                    </div>
                )}
                {/* <span className="collapse-header">
                    {!outputText && inputText}
                    <i className="zmdi zmdi-chevron-down" />
                </span> */}
                {isValidArray(imgArr) && (
                    <>
                        <section className="img-arr-container">
                            {imgArr.map((image) => (
                                <div className="img-container">
                                    <img src={image} className="" alt="img" onClick={() => handleMessageImageEdit(recordId)} />
                                    <button className='delete-btn' onClick={() => handleDeleteImage(recordId, image)}>
                                        <img src="/delete-white.png" />
                                    </button>
                                </div>
                            ))}
                            {/* {!isOwnMsg && (
                                <button onClick={() => handleMessageImageEdit(recordId)} className="edit-icon image-edit-icon">
                                    <img src="/edit-white.png" alt="img" />
                                </button>
                            )} */}
                        </section>
                        {/* <br /> */}
                    </>
                )}
                {isValidArray(pdfArr) && (
                    <>
                        {pdfArr.map((p) => (
                            <PdfCard
                                pdfName={p.split('?')[0].split('/').reverse()[0]}
                                pages={3}
                                fileSize={'120 kB'}
                                handleDelete={() => handleDeleteDocument(recordId, p)}
                            />
                        ))}
                        <br />
                    </>
                )}
                {isValidArray(videoArr) && (
                    <>
                        <p>Company Videos</p>
                        {videoArr.map((v) => (
                            <a target="_blank" href={v}>
                                {v}
                            </a>
                        ))}
                        <br />
                    </>
                )}
                {/* {outputText && <br />} */}
                <div className="message-text">
                    {textEdit
                        ? (
                            <form id="message-edit-form" aria-readonly={false} onSubmit={(e) => { e.preventDefault(); handleMessageEditSubmit(recordId, editedText) }}>
                                <ReactQuill
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                            ['table', 'link', 'image', 'video'],
                                        ],
                                    }}
                                    theme="snow"
                                    defaultValue={editedText}
                                    onChange={(text) => { console.log(text); setEditedText(text) }}
                                />
                                <div id="message-edit-form-btn-grp">
                                    <button id="message-edit-form-submit" type="submit">Submit</button>
                                    <button id="message-edit-form-cancel" type="button" onClick={() => setMessageEditId(null)}>Cancel</button>
                                </div>
                            </form>
                        ) : (
                            <>
                                <div>
                                    {outputText && parse(`${outputText}`)}
                                    {isOwnMsg && inputText && parse(`${inputText}`)}
                                </div>
                                {!isOwnMsg && (
                                    <button onClick={() => setMessageEditId(recordId)} className="edit-icon">
                                        <img src="/edit-white.png" alt="img" />
                                    </button>
                                )}
                            </>
                        )}
                </div>
                <div className="metadata">
                    <span className="time">{time}</span>
                    {isOwnMsg ? TICK : ""}
                </div>
                {options && (
                    <div
                        className="footer-cta"
                        onClick={() => {
                            setReplyOptions(options.split(","));
                            setTimeout(() => setIsReplyOptionOpened(true));
                        }}
                    >
                        <span>
                            <i className="zmdi zmdi-view-list-alt" style={{ marginRight: '0.5em' }}></i>
                        </span>
                        <span>{listTitle}</span>
                    </div>
                )}
            </div>
        </>
    );
};

export default Message;