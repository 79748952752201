import React, { useEffect, useState, useRef } from "react";

import "./chat.scss";
import { ROUTES, ICONS, API_URL } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { deleteData, getData, postData, putData } from "../../utils/restService";
import { formatDate, isValidArray } from "../../utils/helper";
import copyToClipboard from "../../utils/clipboard";
import Message from "./Message";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import addATags from "../../utils/addATags";

const Chat = () => {
  const params = useParams();
  const navigate = useNavigate();
  const bottomRef = useRef(null);
  const { bot_id } = params;

  const [userInfo, setUserInfo] = useState({});
  const [message, setMessage] = useState("Hi");
  const [uploading, setUploading] = useState(false);

  // record_id of the message you are editing 
  const [messageEditId, setMessageEditId] = useState(null);

  // This is the complete message response tree that is fetched from the server when the app loads
  const [messageResponses, setMessageResponses] = useState([]);

  // This is the list of messages that are sent in the window, this is a subset of messageResponses
  const [chatMessages, setChatMessages] = useState([]);

  const listTitle = useRef("");
  const [replyOptions, setReplyOptions] = useState([]);
  const [isReplyOptionOpened, setIsReplyOptionOpened] = useState(false);

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  //record_id of the message of which images you are editing
  const [imageEditMessageId, setImageEditMessageId] = useState('');
  const [lightboxImages, setLightboxImages] = useState([]);

  const { SMILEY } = ICONS;

  const { SCRAPPING_DATA, USER_INFO } = API_URL;

  useEffect(() => {
    if (!bot_id) {
      navigate(ROUTES.LOGIN);
    }
    (async () => {
      const userData = await getData(`${USER_INFO}?bot_id=${bot_id}`);
      if (userData?.data && isValidArray(userData?.data)) {
        const { data } = userData;
        const userInfo = data[0];
        setUserInfo(userInfo);
      }
      const company = await getData(`${SCRAPPING_DATA}?bot_id=${bot_id}`);
      if (isValidArray(company?.data)) {
        const { data } = company;
        setMessageResponses(data);
      }
    })();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages.length]);

  const isForwardToWhatsappCommand = (message) => {
    const messageWords = message.split(' ');
    if (messageWords.length !== 2) {
      return false;
    }
    const phoneNumber = messageWords[1];
    if (phoneNumber.length !== 10) {
      return false;
    }
    return true;
  };

  const callWhatsappForwardProductApi = async (message) => {
    const productId = message.split(' ')[0];
    const phoneNumber = message.split(' ')[1];

    const response = await fetch(`https://cag.bagachat.com:5000/get_product/?bot_id=${bot_id}&phone=${phoneNumber}&product=${productId}`);
    if (response.ok) {
      // TODO: handle success/failure
    }
  }

  const handleSendMessage = () => {
    // Handle support request
    // TODO: Refactor this out to the backend if possible
    if (message === "Support Request") {
      postData(API_URL.SEND_API_TRIGGER, { bot_id, input_keyword: message });
    }

    const isCommand = isForwardToWhatsappCommand(message);
    if (isCommand) {
      callWhatsappForwardProductApi(message)
    }

    addMessage({ input_text: addATags(message), isOwnMsg: true });
    let messageResponse =
      messageResponses.find((msg) => msg.input_text.toLowerCase().split(',').map((input_keyword) => input_keyword.trim()).includes(message.toLowerCase().trim())) ||
      messageResponses[0];

    if (messageResponse) {
      setTimeout(() => {
        addMessage({ ...messageResponse, output_text: addATags(messageResponse.output_text), isOwnMsg: false });
      }, [1000]);
    }
    setMessage("");
    setIsReplyOptionOpened(false);
  };

  const addMessage = (msg) => {
    if (msg) {
      setChatMessages((chatMessages) => [
        ...chatMessages,
        { ...msg, time: formatDate(new Date(), "hh:mm A") },
      ]);
      setMessage("");
    }
  };

  const uploadFiles = async ({ botId, messageId, files }) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append('new_file', file);
    }
    formData.append('bot_id', botId);
    formData.append('record_id', messageId);
    return await postData(API_URL.UPLOAD_FILES, formData);
  };

  const handleMessageEditSubmit = async (messageId, editedText) => {
    const response = await putData(SCRAPPING_DATA, {
      "bot_id": bot_id,
      "record_id": messageId,
      "output_text": editedText
    });
    if (!response.status) {
      alert('Sorry. We could not edit the message, please try again later or contact support.');
      return;
    }
    const updatedMessageResponses = messageResponses.map((message) => {
      if (message.record_id === messageId) {
        return { ...message, output_text: editedText }
      };
      return message;
    });
    setMessageResponses(updatedMessageResponses);

    const updatedChatMessages = chatMessages.map((message) => {
      if (message.record_id === messageId) {
        return { ...message, output_text: editedText }
      };
      return message;
    });
    setChatMessages(updatedChatMessages);
    setMessageEditId(null);
  };

  const handleUpload = async (messageId, files) => {
    setUploading(true);
    const response = await uploadFiles({ botId: bot_id, messageId, files });
    setUploading(false);
    addMediaToMessage(messageId, response.data.images, response.data.pdfs);
  };

  const addMediaToMessage = (messageId, images, documents) => {
    console.log('messageId, images', messageId, images);
    const message = messageResponses.find((message) => message.record_id === messageId);

    setLightboxImages([...message.img.split(','), ...images]);

    setMessageResponses(prevResponses => prevResponses.map(message => {
      if (message.record_id === messageId) {
        return {
          ...message,
          img: (message.img ? message.img + ',' : '') + images.join(','),
          pdf: (message.pdf ? message.pdf + ',' : '') + documents.join(',')
        };
      }
      return message;
    }));

    setChatMessages(prevMessages => prevMessages.map(message => {
      if (message.record_id === messageId) {
        return {
          ...message,
          img: (message.img ? message.img + ',' : '') + images.join(','),
          pdf: (message.pdf ? message.pdf + ',' : '') + documents.join(',')
        };
      }
      return message;
    }));
  };

  const removeImageFromMessage = async (messageId, imageUrl) => {
    const message = messageResponses.find((message) => message.record_id === messageId);

    const response = await deleteData(API_URL.UPLOAD_FILE, {
      bot_id,
      record_id: messageId,
      file: imageUrl
    });

    if (!response.status) {
      alert('Sorry. We could not delete the image, please try again later or contact support.');
      return;
    };

    setLightboxImages(message.img.split(',').filter(url => url !== imageUrl && url !== ""));

    const updatedMessageResponses = messageResponses.map((message) => {
      if (message.record_id === messageId) {
        return { ...message, img: message.img.split(',').filter(url => url !== imageUrl && url !== "").join(',') }
      };
      return message;
    });
    setMessageResponses(updatedMessageResponses);

    const updatedChatMessages = chatMessages.map((message) => {
      if (message.record_id === messageId) {
        return { ...message, img: message.img.split(',').filter(url => url !== imageUrl && url !== "").join(',') }
      };
      return message;
    });
    setChatMessages(updatedChatMessages);
  };

  const addDocumentsToMessage = (messageId, documents) => {
    const updatedMessageResponses = messageResponses.map((message) => {
      if (message.record_id === messageId) {
        return { ...message, pdf: message.pdf + ',' + documents.join(',') }
      };
      return message;
    });
    setMessageResponses(updatedMessageResponses);

    const updatedChatMessages = chatMessages.map((message) => {
      if (message.record_id === messageId) {
        return { ...message, pdf: message.pdf + ',' + documents.join(',') }
      };
      return message;
    });
    setChatMessages(updatedChatMessages);
  };

  const removeDocumentFromMessage = async (messageId, documentUrl) => {
    const response = await deleteData(API_URL.UPLOAD_FILE, {
      bot_id,
      record_id: messageId,
      file: documentUrl
    });

    if (!response.status) {
      alert('Sorry. We could not delete the document, please try again later or contact support.');
      return;
    };

    const updatedMessageResponses = messageResponses.map((message) => {
      if (message.record_id === messageId) {
        return { ...message, pdf: message.pdf.split(',').filter(url => url !== documentUrl && url !== "").join(',') }
      };
      return message;
    });
    setMessageResponses(updatedMessageResponses);

    const updatedChatMessages = chatMessages.map((message) => {
      if (message.record_id === messageId) {
        return { ...message, pdf: message.pdf.split(',').filter(url => url !== documentUrl && url !== "").join(',') }
      };
      return message;
    });
    setChatMessages(updatedChatMessages);
  };

  const handleMessageImageEdit = (messageId) => {
    const message = messageResponses.find((msg) => msg.record_id === messageId);
    if (!message) {
      // Handle error
      return;
    };
    setImageEditMessageId(messageId);
    setLightboxImages(message.img.split(',').filter(url => url !== ""));
    setIsLightboxOpen(true);
  };

  const scrollToBottom = () => {
    const element = document.getElementsByClassName(
      "conversation-container"
    )[0];
    element.scrollTop = element.scrollHeight;
  };

  return (
    <div className="marvel-device iphone-x">
      <div className="top-bar"></div>
      <div className="sleep"></div>
      <div className="volume"></div>
      <div className="camera"></div>
      <div className="screen">
        <div className="screen-container">
          <Lightbox
            open={isLightboxOpen}
            close={() => setIsLightboxOpen(false)}
            slides={lightboxImages.map((imgUrl) => ({ src: imgUrl }))}
            plugins={[Thumbnails, Download]}
            carousel={{ finite: true, preload: 10 }}
            thumbnails={{ width: 120, height: 100, padding: 0 }}
            toolbar={{
              buttons: [
                "download",
                // <button key="upload-more-images" type="button" className="yarl__button upload-more-images-btn">
                //   <input type="file" id="imageInput" multiple={false} style={{ display: 'none' }} onChange={async (e) => {
                //     addImageToMessage(imageEditMessageId, e.target.files[0]);
                //   }} />
                //   <label htmlFor="imageInput">
                //     <img src="/upload-white.png" alt="Upload More Images" height={32} width={32} style={{ marginBottom: '-0.3em' }} />
                //   </label>
                // </button>,
                "slideshow",
                "close",
              ],
            }}
            render={{
              thumbnail: ({ slide }) => (
                <div style={{ position: "relative" }}>
                  {lightboxImages.length > 1 && (
                    <button onClick={() => { removeImageFromMessage(imageEditMessageId, slide.src); }} className="thumbnail-delete-btn">
                      <img src="/delete-white.png" />
                    </button>
                  )}
                  <img src={slide.src} style={{ height: 100, width: 120 }} />
                </div>
              )
            }}
          />
          <div className="status-bar">
            <div>
              <div className="time">{formatDate(new Date())}</div>
              <div className="battery">
                <i className="zmdi zmdi-battery"></i>
              </div>
              <div className="network">
                <i className="zmdi zmdi-network"></i>
              </div>
              <div className="wifi">
                <i className="zmdi zmdi-wifi-alt-2"></i>
              </div>
              <div className="star">
                <i className="zmdi zmdi-star"></i>
              </div>
            </div>
            <button id="copy-bot-id" onClick={() => copyToClipboard(bot_id)}>Copy BOT-ID</button>
          </div>
          <div className="chat">
            <div className="chat-container">
              <div className="user-bar">
                <div className="back">
                  <i
                    className="zmdi zmdi-arrow-left"
                    onClick={() => navigate("/")}
                  ></i>
                </div>
                <div className="avatar">
                  <img src="/avatar.jpg" alt="Avatar" />
                </div>
                <div className="name">
                  <span>
                    {userInfo?.companyName || userInfo?.phone || "Unknown"}
                  </span>
                  <span className="status">online</span>
                </div>
                <div className="actions more">
                  <i className="zmdi zmdi-more-vert"></i>
                </div>
                <div className="actions attachment">
                  <i className="zmdi zmdi-attachment-alt"></i>
                </div>
                <div className="actions">
                  <i className="zmdi zmdi-phone"></i>
                </div>
              </div>
              <div className="conversation">
                <div className="conversation-container">
                  {isValidArray(chatMessages) &&
                    chatMessages.map((msg) =>
                      <Message
                        recordId={msg.record_id}
                        inputText={msg.input_text}
                        outputText={msg.output_text}
                        img={msg.img}
                        pdf={msg.pdf}
                        video={msg.video}
                        options={msg.options}
                        time={msg.time}
                        isOwnMsg={msg.isOwnMsg}
                        listTitle={msg.list_title}
                        setReplyOptions={setReplyOptions}
                        setIsReplyOptionOpened={setIsReplyOptionOpened}
                        setMessageEditId={setMessageEditId}
                        handleMessageImageEdit={handleMessageImageEdit}
                        handleMessageEditSubmit={handleMessageEditSubmit}
                        textEdit={messageEditId === msg.record_id}
                        handleUpload={handleUpload}
                        handleDeleteImage={removeImageFromMessage}
                        handleDeleteDocument={removeDocumentFromMessage}
                        uploading={uploading}
                      />
                    )}
                  <div
                    className={`list-popup m-${isReplyOptionOpened ? "fadeIn" : "fadeOut"
                      }`}
                  >
                    <div className="popup-header">
                      <span>
                        <i
                          className="zmdi zmdi-close"
                          onClick={() => setIsReplyOptionOpened(false)}
                        />
                      </span>
                      <span>{listTitle.current}</span>
                    </div>
                    {isValidArray(replyOptions) && (
                      <div style={{ overflow: "scroll" }}>
                        <ul className="options-ul">
                          {replyOptions
                            .filter((r) => r && r)
                            .map((option, ind) => (
                              <li
                                onClick={() => {
                                  setMessage(option);
                                }}
                              >
                                <label for="html">{option}</label>
                                <input
                                  type="radio"
                                  id={ind}
                                  name="reply-option"
                                  value={option}
                                  className="radio-btn"
                                />
                              </li>
                            ))}
                        </ul>
                        {message && (
                          <form
                            className="conversation-compose choose-one-input"
                            style={{ border: "2px solid lightgray" }}
                          >
                            <input
                              className="input-msg"
                              name="input"
                              value={message}
                              disabled
                            />
                            <button
                              className="send"
                              onClick={(e) => {
                                e.preventDefault();
                                handleSendMessage();
                              }}
                            >
                              <div className="circle">
                                <i className="zmdi zmdi-mail-send"></i>
                              </div>
                            </button>
                          </form>
                        )}
                      </div>
                    )}
                  </div>
                  <div ref={bottomRef} />
                </div>
                <form className="conversation-compose">
                  <div className="emoji">{SMILEY}</div>
                  <input
                    className="input-msg"
                    name="input"
                    placeholder="Type a message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <div className="photo">
                    <i className="zmdi zmdi-camera"></i>
                  </div>
                  <button
                    className="send"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSendMessage();
                    }}
                  >
                    <div className="circle">
                      <i className="zmdi zmdi-mail-send"></i>
                    </div>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
