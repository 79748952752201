import { Navigate, useRoutes } from "react-router-dom";

import Login from "./components/Login/login";
import Chat from "./components/Chat/chat";
import { ROUTES } from "./utils/constants";

export default function AppRouter() {
  const { DEFAULT, LOGIN, CHAT } = ROUTES;
  return useRoutes([
    {
      path: DEFAULT,
      // path: "/",
      element: <Login />,
    },
    {
      path: LOGIN,
      element: <Login />,
    },
    {
      path: CHAT,
      element: <Chat />,
    },
    { path: "*", element: <Navigate to={DEFAULT} replace /> },
  ]);
}
